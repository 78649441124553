<template>
  <div>
    <el-drawer
      title="编辑广告组"
      :visible="editGroupShow"
      direction="rtl"
      @close="close"
      wrapperClosable
      size="1200px"
    >
      <div
        class="drawerCon"
        v-loading="loading"
      >
        <div class="editAdsCon">
          <audience
            :initData="initData"
            :accountId="account"
            :adSeriesInfo="adSeriesInfo"
            :adset-id="id"
            :audienceInfo="audienceInfo"
            @audienceMsg="audienceMsg"
            :rowThirdNum="rowThirdNum"
            :smart_promotion_type="smart_promotion_type"
            ref="audience"
            :type="type"
          ></audience>
          <div
            class="campainCon"
            style="width: 1000px"
          >
            <el-form
              label-width="140px"
              :model="yusuan"
            >
              <el-form-item
                label="当前像素ID："
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <p>{{ adSeriesInfo.fbPixelId }}</p>
              </el-form-item>
              <el-form-item label="广告系列预算优化：">
                <el-switch
                  v-model="is_cbo"
                  disabled
                ></el-switch>
                <p class="tips">
                  启用广告系列预算优化(CBO)，系统会将广告系列预算根据广告组的实时表现分配给广告组，以获得更多转化。
                </p>
              </el-form-item>
              <el-form-item
                label="广告组预算："
                v-if="!is_cbo"
              >
                <el-input v-model="adSetInfo.budget">
                  <el-select
                    v-model="adSetInfo.budget_type"
                    slot="prepend"
                    placeholder="请选择"
                    @change="selectBudgetType"
                    disabled
                  >
                    <el-option
                      label="单日预算"
                      value="daily"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="lifetime"
                    ></el-option>
                  </el-select>
                  <el-button slot="append">{{ currencyName }}</el-button>
                </el-input>
              </el-form-item>
              <el-form-item
                label="广告组花费限额："
                v-show="is_cbo"
              >
                <el-checkbox v-model="adsChecked">广告组花费限额</el-checkbox>
                <div
                  class="dailySpendCap"
                  v-show="adsChecked"
                >
                  <div class="l">
                    <p>{{ adSetInfo.budget_type == 'daily' ? '单日' : '总' }}花费下限</p>
                    <el-input
                      v-if="adSetInfo.budget_type == 'daily'"
                      placeholder="请输入单日花费下限"
                      type="number"
                      v-model="adSetInfo.daily_min_spend_target"
                      :min="0"
                      ><template slot="append">$</template></el-input
                    >
                    <el-input
                      v-else
                      placeholder="请输入总花费下限"
                      type="number"
                      v-model="adSetInfo.lifetime_min_spend_target"
                      :min="0"
                      ><template slot="append">$</template></el-input
                    >
                    <span>我们无法保证花费金额与花费下限完全吻合</span>
                  </div>
                  <div class="r">
                    <p>{{ adSetInfo.budget_type == 'daily' ? '单日' : '总' }}花费上限</p>
                    <el-input
                      v-if="adSetInfo.budget_type == 'daily'"
                      placeholder="请输入单日花费上限"
                      type="number"
                      v-model="adSetInfo.daily_spend_cap"
                      :min="0"
                      ><template slot="append">$</template></el-input
                    >
                    <el-input
                      v-else
                      placeholder="请输入总花费上限"
                      type="number"
                      v-model="adSetInfo.lifetime_spend_cap"
                      :min="0"
                      ><template slot="append">$</template></el-input
                    >
                    <span>你的花费不会超过这个金额</span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="广告组排期："
                v-if="!is_cbo"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <div style="margin-bottom: 10px">
                  <span style="font-size: 12px; color: #666">开始时间：</span>
                  <el-date-picker
                    type="datetime"
                    v-model="adSetInfo.start_date"
                    placeholder="选择日期时间"
                    disabled
                  >
                  </el-date-picker>
                  <span class="timezoneName">{{ timezoneName }}</span>
                </div>
                <div>
                  <span style="font-size: 12px; color: #666">结束时间：</span>
                  <el-date-picker
                    type="datetime"
                    v-model="adSetInfo.end_date"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                  <span class="timezoneName">{{ timezoneName }}</span>
                </div>
              </el-form-item>
              <el-form-item
                label="分时段投放："
                v-if="!is_cbo && adSetInfo.budget_type == 'lifetime'"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-checkbox
                  v-model="adSetInfo.pacing_type"
                  true-label="day_parting"
                  false-label="standard"
                  >是否分时段投放</el-checkbox
                >
                <el-tooltip
                  effect="dark"
                  content="批量修改广告组投放时段"
                  placement="top-start"
                  v-if="adSetInfo.pacing_type == 'day_parting'"
                >
                  <el-button
                    size="mini"
                    @click="setTimePickerBtn"
                    type="text"
                  >
                    <img
                      src="../../../../assets/icon/editTimes.png"
                      alt=""
                      style="width: 24px; height: 24px; vertical-align: middle; margin-left: 10px"
                    />
                  </el-button>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="投放优化目标：">
                <el-select
                  v-model="adSetInfo.optimization_goal"
                  placeholder="请选择"
                  @change="setTarget"
                  v-if="initData.ad"
                  disabled
                >
                  <el-option
                    :label="item.desc"
                    :value="item.key"
                    v-for="(item, index) in initData.ad.AdSetOptimizationGoal"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="投放策略：">
                <span class="celue">
                  <span>{{
                    adSetInfo.bid_strategy == 'LOWEST_COST_WITHOUT_CAP'
                      ? '自动竞价'
                      : adSetInfo.bid_strategy == 'COST_CAP'
                      ? '目标费用'
                      : adSetInfo.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
                      ? '最低ROAS竞价'
                      : '手动出价'
                  }}</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="使用出价上限竞价策略，Facebook 会尽可能获得最多展示次数，且在每次竞拍中，竞价金额不会超过$2，按展示次数计费"
                    placement="top-start"
                  >
                    <i
                      class="el-icon-warning-outline"
                      style="margin: 0 10px"
                    ></i>
                  </el-tooltip>
                </span>
                <el-button
                  type="text"
                  @click="setStrategyShow = true"
                  v-if="!setStrategyShow"
                  >调整</el-button
                >
                <el-button
                  type="text"
                  @click="setStrategyShow = false"
                  v-if="setStrategyShow"
                  >收起</el-button
                >
              </el-form-item>
              <!-- 广告受益方 -->
              <el-form-item label="广告受益方">
                <el-input
                  v-model="adSetInfo.dsa_beneficiary"
                  placeholder="请输入广告收益方"
                ></el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom"
                >
                  <p slot="content">
                    奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
                  </p>
                  <p class="tips">欧盟必填</p>
                </el-tooltip>
              </el-form-item>
              <!-- 广告受益方 -->
              <el-form-item label="广告赞助方">
                <el-input
                  v-model="adSetInfo.dsa_payor"
                  placeholder="请输入广告赞助方"
                ></el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom"
                >
                  <p slot="content">
                    奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
                  </p>
                  <p class="tips">欧盟必填</p>
                </el-tooltip>
              </el-form-item>
            </el-form>
            <div
              class="starategy"
              v-if="setStrategyShow || preview"
            >
              <!-- <el-form
              :model="adSetInfo"
              :rules="rules"
              ref="ruleForm"
              label-width="140px"
              class="demo-ruleForm"
            >
              <el-form-item
                :label="`${is_cbo ? '广告系列' : '广告组'}竞价策略：`"
                prop="resource"
              >
                <el-radio-group
                  v-model="adSetInfo.bid_strategy"
                  :disabled="is_cbo"
                >
                  <el-radio
                    :label="item.key"
                    v-for="(item, i) in bidStrategyList"
                    :key="i"
                    >{{ item.desc }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="费用控制额："
                prop="name"
                v-if="adSetInfo.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'"
              >
                <el-input
                  v-model="adSetInfo.bid_amount"
                  style="width：200px"
                >
                  <el-button
                    slot="append"
                    v-if="adSetInfo.bid_strategy != 'LOWEST_COST_WITH_MIN_ROAS'"
                    >{{ currencyName }}</el-button
                  >
                </el-input>
                <span
                  style="margin-left: 15px"
                  v-if="adSetInfo.bid_strategy != 'LOWEST_COST_WITH_MIN_ROAS'"
                  >单次转化量费用</span
                >
              </el-form-item>
              <el-form-item
                label="转化时间窗："
                prop="region"
                v-if="
                  initData.ad &&
                  adSeriesInfo.objective == 'OUTCOME_SALES' &&
                  adSetInfo.optimization_goal == 'OFFSITE_CONVERSIONS' &&
                  adSetInfo.optimization_goal !== 'VALUE'
                "
              >
                <el-select
                  v-model="adSetInfo.attribution_spec"
                  :disabled="is_cbo"
                >
                  <el-option
                    :label="times.desc"
                    :value="times.key"
                    v-for="times in initData.ad.AdSetAttributionSpec"
                    :key="times.key"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="计费方式："
                prop="resource"
              >
                <el-radio-group
                  v-model="adSetInfo.billing_event"
                  :disabled="is_cbo"
                >
                  <el-radio
                    :label="types.key"
                    v-for="types in targetTypeList"
                    :key="types.key"
                    >{{ types.desc }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-form> -->
              <bid-amount
                :bidAmountMsg="adSetInfo"
                @getBidAmountMsg="getBidAmountMsg"
                level="adset"
                :editAdGroup="true"
              ></bid-amount>
            </div>
          </div>
          <div
            class="cover"
            v-if="preview"
          ></div>
        </div>
      </div>
      <div
        class="demo-drawer__footer"
        v-if="!preview"
      >
        <p></p>
        <div>
          <el-button
            type="primary"
            @click="close"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="sure"
            id="editAdsetEvent"
            >保存</el-button
          >
        </div>
        <p></p>
      </div>
    </el-drawer>
    <!-- 选择时段 -->
    <slect-times-drag
      :setTimePickerShow.sync="setTimePickerShow"
      :value="timesList"
      @setTimePicker="setTimePicker"
      @sure="setCurrentTimes"
    ></slect-times-drag>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import audience from './audience';
import { adForUpdate, updateAdSet } from '@/api/creatAd.js';
import { fnCSTToYmd, fnMsToCST } from '@/utils/utils';
import slectTimesDrag from './selectTimeDrag/index.vue';
import bidAmount from '../../components/batchEdit/adset/bidAmount.vue';
export default {
  props: ['editGroupShow', 'accountId', 'id', 'preview', 'type', 'adsetScope'],
  data() {
    return {
      smart_promotion_type: null,
      is_cbo: false,
      yusuan: {},
      ruleForm: {},
      rules: {},
      setStrategyShow: false,
      adSetInfo: {},
      adSeriesInfo: {},
      targetTypeList: [
        { name: '转化量', id: 1 },
        { name: '落地页浏览量', id: 2 },
        { name: '链接点击量', id: 3 },
        { name: '展示次数', id: 4 },
        { name: '单日独立覆盖人数', id: 5 },
      ],
      peopleGroup: [
        { name: '该地区内的所有用户', id: 'all' },
        { name: '居住在该地区的用户', id: 'home' },
        { name: '最近位于该地区的用户', id: 'recent' },
        { name: '到该地区旅游的用户', id: 'travel_in' },
      ],
      typeList: [
        { key: 'interestsuggestion', name: '荐词' },
        { key: 'interests', name: '兴趣' },
        { key: 'behaviors', name: '行为' },
        { key: 'custom_audience', name: '自定义受众' },
        { key: 'education_schools', name: '学校' },
        { key: 'education_statuses', name: '学历' },
        { key: 'family_statuses', name: '家庭状况' },
        { key: 'income', name: '收入' },
        { key: 'life_events', name: '生活纪事' },
        { key: 'relationship_statuses', name: '感情状况' },
        { key: 'industries', name: '行业' },
      ],
      audienceInfo: {},
      audienceInfoNew: [],
      draftCampaignId: 0,
      loading: false,
      setTimePickerShow: false,
      timesList: [],
      saveParams: {},
      adsChecked: false,
    };
  },
  components: {
    audience,
    slectTimesDrag,
    bidAmount,
  },
  computed: {
    ...mapGetters(['toFormatedMoney', 'toBaseMoney', 'toBaseRoas', 'toFormatedRoas']),
    currencyName() {
      try {
        return this.$store.getters.currentUiUnit.name;
      } catch (e) {
        console.warn(e);
      }
    },
    timezoneName() {
      // console.log(this.$store.getters);
      return this.$store.getters.currentAccountInfo.timezoneName;
    },
    ...mapGetters('initData', ['optimizationGoals', 'getBidStrategyList']),
    ...mapState('initData', ['initData']),
    account() {
      if (this.adsetScope && this.adsetScope.row.rowAccountId) {
        return this.adsetScope.row.rowAccountId;
      } else {
        return this.accountId;
      }
    },
    rowThirdNum() {
      if (this.adsetScope && this.adsetScope.row.rowThirdNum) {
        return this.adsetScope.row.rowThirdNum;
      } else {
        return null;
      }
    },
    bidStrategyList() {
      return this.getBidStrategyList({
        is_cbo: this.is_cbo,
        objective: this.adSeriesInfo.objective,
        optimization_goal: this.adSetInfo.optimization_goal,
        smart_promotion_type: this.smart_promotion_type,
      });
    },
  },
  methods: {
    formatBid(val, bidStrategy) {
      if (bidStrategy == 'LOWEST_COST_WITH_MIN_ROAS') {
        return val / 10000;
      } else {
        return this.$store.getters.toFormatedMoney(val).value;
      }
    },
    baseBid(val, bidStrategy) {
      if (bidStrategy == 'LOWEST_COST_WITH_MIN_ROAS') {
        return val * 10000;
      } else {
        return this.$store.getters.toBaseMoney(val);
      }
    },
    // 关闭弹窗
    close() {
      this.$emit('close', 'editGroupShow');
    },
    // 获取竞价信息
    getBidAmountMsg(v) {
      this.adSetInfo = { ...this.adSetInfo, ...v };
    },
    // 点击保存
    sure() {
      if (
        this.$refs.audience?.statusData?.invaild_insterest &&
        this.$refs.audience.statusData.invaild_insterest.length > 0
      ) {
        this.$notify.error('存在无效的兴趣词，请删除后再提交');
        return;
      }
      let info = JSON.parse(JSON.stringify(this.adSetInfo));
      // console.log(info);
      info.start_date = fnCSTToYmd(new Date(info.start_date));
      info.end_date = info.end_date ? fnCSTToYmd(new Date(info.end_date)) : null;
      info.budget = this.$store.getters.toBaseMoney(info.budget);
      if (info.bid_strategy == 'COST_CAP' || info.bid_strategy == 'LOWEST_COST_WITH_BID_CAP') {
        if (!info.bid_amount) {
          this.$message({
            type: 'warning',
            message: '选择目标费用或者手动出价必须设置费用控制额',
          });
          return false;
        }
      }
      info.bid_amount = info.bid_strategy == 'LOWEST_COST_WITHOUT_CAP' ? null : info.bid_amount;
      info.roas_average_floor = info.roas_average_floor ? info.roas_average_floor / 1 : null;
      let targeting = JSON.parse(JSON.stringify(this.audienceInfoNew));
      targeting[0].geo_locations = targeting[0].geo_locations ? targeting[0].geo_locations : [];
      targeting[0].geo_locations_region = targeting[0].geo_locations_region
        ? targeting[0].geo_locations_region.map((v) => v.key)
        : [];
      targeting[0].geo_locations_city = targeting[0].geo_locations_city
        ? targeting[0].geo_locations_city.map((v) => v.key)
        : [];
      if (
        targeting[0].excluded_geo_locations_places &&
        targeting[0].excluded_geo_locations_places.length &&
        !targeting[0].excluded_geo_locations_place_radius
      ) {
        this.$message({
          type: 'warning',
          message: '请填写排除乡镇公里数',
        });
        return false;
      }
      if (
        !targeting[0].geo_locations.length &&
        !targeting[0].geo_locations_region.length &&
        !targeting[0].geo_locations_city.length
      ) {
        this.$message({
          type: 'warning',
          message: '受众地区不能为空',
        });
        return false;
      }
      if (info.budget_type == 'daily') {
        info.daily_min_spend_target = info.daily_min_spend_target
          ? Number((info.daily_min_spend_target * 100).toFixed(2))
          : null;
        info.daily_spend_cap = info.daily_spend_cap ? Number((info.daily_spend_cap * 100).toFixed(2)) : null;
      } else {
        info.lifetime_min_spend_target = info.lifetime_min_spend_target
          ? Number((info.lifetime_min_spend_target * 100).toFixed(2))
          : null;
        info.lifetime_spend_cap = info.lifetime_spend_cap ? Number((info.lifetime_spend_cap * 100).toFixed(2)) : null;
      }
      let params = {
        level: 'adset',
        type: this.type,
        adSet: { ...info, targeting: targeting },
        cbo: this.is_cbo,
        ...this.saveParams,
      };
      if (this.type == 'DRAFT') {
        params.draftCampaignId = this.draftCampaignId;
        params.adsetId = this.id;
      } else {
        params.accountId = this.account;
        params.adSetId = this.id;
      }
      this.$showLoading();
      updateAdSet(params, this.rowThirdNum).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('get-list', this.adsetScope);
          this.close();
        }
      });
    },
    // 接收广告受众信息
    audienceMsg(v) {
      this.audienceInfoNew = v;
      // console.log(v);
    },
    // 设置分时段投放
    setTimePickerBtn() {
      this.setTimePickerShow = true;
      let list = this.adSetInfo.adset_schedule || [];
      list.map((k) => {
        k.from = k.start_minute / 60 + ': 00';
        k.to = k.end_minute / 60 + ': 00';
      });
      this.timesList = list;
    },
    // 接收组件选择的时段
    setTimePicker(v) {
      this.timesList = JSON.parse(JSON.stringify(v));
    },
    //
    setCurrentTimes() {
      this.adSetInfo.adset_schedule = JSON.parse(JSON.stringify(this.timesList));
    },
    // 选择优化目标
    setTarget(v, flag) {
      console.log(this.initData);
      let list = this.initData.ad.AdSetOptimizationGoal.filter((k) => k.key == v);
      this.targetTypeList = list[0].billingEvents;
      if (!flag) {
        if (v !== 'OFFSITE_CONVERSIONS') {
          this.adSetInfo.attribution_spec = null;
        }
        if (v !== 'VALUE') {
          this.adSetInfo.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
        }
      }

      // console.log(this.targetTypeList);
    },
    // 选择预算类型
    selectBudgetType(v) {
      if (v == 'daily') {
        this.adSetInfo.pacing_type = 'standard';
      }
    },
    // 获取广告组信息
    getAdsetMsg() {
      let params = {
        level: 'adset',
        type: this.type,
      };
      if (this.type == 'DRAFT') {
        params.draftId = this.id;
      } else {
        params.accountId = this.account;
        params.fbId = this.id;
      }
      this.loading = true;
      adForUpdate(params, this.rowThirdNum).then((res) => {
        this.smart_promotion_type = res.data.smart_promotion_type;
        this.loading = false;
        // console.log(res);
        this.adSetInfo = JSON.parse(JSON.stringify(res.data.adSet));
        this.adSetInfo.budget = this.adSetInfo.budget
          ? this.$store.getters.toFormatedMoney(this.adSetInfo.budget).value
          : 0;
        // this.adSetInfo.bid_amount = this.adSetInfo.bid_amount
        //   ? this.formatBid(this.adSetInfo.bid_amount, this.adSetInfo.bid_strategy)
        //   : 0;
        this.adSetInfo.roas_average_floor = this.adSetInfo.roas_average_floor
          ? this.adSetInfo.roas_average_floor / 1
          : null;
        if (this.adSetInfo.budget_type == 'daily') {
          this.adSetInfo.daily_min_spend_target = this.adSetInfo.daily_min_spend_target
            ? (this.adSetInfo.daily_min_spend_target / 100).toFixed(2)
            : null;
          this.adSetInfo.daily_spend_cap = this.adSetInfo.daily_spend_cap
            ? (this.adSetInfo.daily_spend_cap / 100).toFixed(2)
            : null;
        } else {
          this.adSetInfo.lifetime_min_spend_target = this.adSetInfo.lifetime_min_spend_target
            ? (this.adSetInfo.lifetime_min_spend_target / 100).toFixed(2)
            : null;
          this.adSetInfo.lifetime_spend_cap = this.adSetInfo.lifetime_spend_cap
            ? (this.adSetInfo.lifetime_spend_cap / 100).toFixed(2)
            : null;
        }
        this.adSetInfo.objective = res.data.objective;
        this.adSetInfo.is_cbo = res.data.cbo;
        if (
          this.adSetInfo.daily_min_spend_target ||
          this.adSetInfo.daily_spend_cap ||
          this.adSetInfo.lifetime_min_spend_target ||
          this.adSetInfo.lifetime_spend_cap
        ) {
          this.adsChecked = true;
        }

        this.is_cbo = res.data.cbo;
        this.draftCampaignId = res.data.draftCampaignId;
        this.audienceInfoNew = res.data.adSet.targeting;
        this.setTarget(this.adSetInfo.optimization_goal, true);
        this.adSeriesInfo = {
          objective: res.data.objective,
          fbPixelId: res.data.fbPixelId,
          customEventType: res.data.customEventType,
          promotedPageId: res.data.adSet.promoted_page_id,
        };
        let { adSet, ...saveParams } = { ...res.data };
        this.saveParams = saveParams;
        let targetingInfo = res.data.adSet.targeting[0];
        let geo_locations_region = [],
          excluded_geo_locations_regions = [],
          excluded_geo_locations_cities = [],
          excluded_geo_locations_places = [],
          excluded_geo_locations_subcities = [];
        let regions = [],
          citys = [],
          places = [];
        this.initData.targeting.TargetingGeoRegions.map((c) => {
          regions = regions.concat(c.regions);
        });
        this.initData.targeting.TargetingGeoCitys.map((c) => {
          citys = citys.concat(c.cities);
        });
        let subCitys = [];
        this.initData.targeting.TargetingGeoSubCities.map((c) => {
          subCitys = citys.concat(c.cities);
        });
        this.initData.targeting.TargetingGeoPlaces.map((c) => {
          places = places.concat(c.places);
        });
        if (targetingInfo.geo_locations_region) {
          targetingInfo.geo_locations_region.map((v) => {
            console.log(regions.filter((k) => k.key == v));
            geo_locations_region = geo_locations_region.concat(regions.filter((k) => k.key == v));
          });
        }
        if (targetingInfo.excluded_geo_locations_regions) {
          targetingInfo.excluded_geo_locations_regions.map((v) => {
            console.log(regions.filter((k) => k.key == v));
            excluded_geo_locations_regions = excluded_geo_locations_regions.concat(regions.filter((k) => k.key == v));
          });
        }
        if (targetingInfo.excluded_geo_locations_cities) {
          targetingInfo.excluded_geo_locations_cities.map((v) => {
            console.log(citys.filter((k) => k.key == v));
            excluded_geo_locations_cities = excluded_geo_locations_cities.concat(citys.filter((k) => k.key == v));
          });
        }
        if (targetingInfo.excluded_geo_locations_subcities) {
          targetingInfo.excluded_geo_locations_subcities.map((v) => {
            console.log(citys.filter((k) => k.key == v));
            excluded_geo_locations_subcities = excluded_geo_locations_subcities.concat(
              subCitys.filter((k) => k.key == v),
            );
          });
        }
        if (targetingInfo.excluded_geo_locations_places) {
          targetingInfo.excluded_geo_locations_places.map((v) => {
            console.log(places.filter((k) => k.key == v));
            excluded_geo_locations_places = excluded_geo_locations_places.concat(places.filter((k) => k.key == v));
          });
        }
        let geo_locations_city = [];
        if (targetingInfo.geo_locations_city) {
          targetingInfo.geo_locations_city.map((v) => {
            geo_locations_city = geo_locations_city.concat(citys.filter((k) => k.key == v));
          });
        }
        let audienceInfo = {
          connections: targetingInfo.connections,
          excluded_connections: targetingInfo.excluded_connections,
          friends_of_connections: targetingInfo.friends_of_connections,
          age_max: targetingInfo.age_max,
          age_min: targetingInfo.age_min,
          geo_locations: targetingInfo.geo_locations,
          geo_locations_region: geo_locations_region,
          geo_locations_city: geo_locations_city,
          excluded_geo_locations: targetingInfo.excluded_geo_locations,
          excluded_geo_locations_regions: excluded_geo_locations_regions,
          excluded_geo_locations_cities: excluded_geo_locations_cities,
          excluded_geo_locations_subcities: excluded_geo_locations_subcities,
          excluded_geo_locations_places: excluded_geo_locations_places,
          excluded_geo_locations_place_radius: targetingInfo.excluded_geo_locations_place_radius,
          locales: targetingInfo.locales,
          location_types: targetingInfo.location_types,
          peopleGroup: this.peopleGroup.filter((v) => v.id == targetingInfo.location_types)[0].name,
          targeting_optimization: targetingInfo.targeting_optimization,
          excludeAudienceShow: false,
          excludeCountryShow:
            targetingInfo.excluded_geo_locations ||
            targetingInfo.excluded_geo_locations_regions ||
            targetingInfo.excluded_geo_locations_cities ||
            targetingInfo.excluded_geo_locations_places
              ? true
              : false,
          genders: targetingInfo.genders
            ? targetingInfo.genders.length == 2
              ? 'all'
              : targetingInfo.genders.length == 1 && targetingInfo.genders[0] === 'F'
              ? 'F'
              : 'M'
            : 'all',
          user_device: targetingInfo.user_device,
          user_os: targetingInfo.user_os,
          device_platforms: targetingInfo.device_platforms,
          facebook_positions: targetingInfo.facebook_positions,
          instagram_positions: targetingInfo.instagram_positions,
          messenger_positions: targetingInfo.messenger_positions,
          audience_network_positions: targetingInfo.audience_network_positions,
          targeting_automation: targetingInfo.targeting_automation || {
            advantage_audience: 0,
          },
        };
        // 判断是否存在排除受众
        audienceInfo.notPeople = [];
        if (targetingInfo.exclusions) {
          audienceInfo.notPeople.push(targetingInfo.exclusions);
        }
        // 处理受众
        targetingInfo.audienceListAll = [];
        // console.log(targetingInfo.flexible_spec);
        audienceInfo.flexible_spec = [].concat(targetingInfo.flexible_spec || [{}]);
        console.log(audienceInfo);
        this.audienceInfo = JSON.parse(JSON.stringify(audienceInfo));
      });
    },
  },
  watch: {
    initData: {
      handler() {
        // console.log(this.initData);
      },
      deep: true,
    },
    editGroupShow() {
      if (this.editGroupShow) {
        this.getAdsetMsg();
      }
    },
  },
};
</script>
<style lang="scss">
.editAdsCon {
  padding-bottom: 50px;
  .el-tabs__header {
    display: none;
  }
}
.campainCon {
  padding: 0 20px;
  // padding-bottom: 20px;
  .el-form-item__content .el-input-group {
    width: 300px;
  }
  .el-input-group__prepend {
    width: 120px;
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
}
.starategy {
  // width: 60%;
  padding: 20px;
  border: 1px dashed #ddd;
}
.timezoneName {
  margin-left: 20px;
  color: #999;
}
.dailySpendCap {
  display: flex;
  color: #666;
  width: 500px;
  .l {
    margin-right: 20px;
    width: 300px;
  }
  .r {
    width: 300px;
  }
  ::v-deep.el-input {
    width: 100% !important;
  }
  span {
    font-size: 12px;
    color: #999;
    display: block;
  }
}
</style>
