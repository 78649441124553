<template>
  <div>
    <el-drawer
      title="编辑广告系列"
      :visible="editCampainShow"
      direction="rtl"
      @close="close"
      wrapperClosable
      size="1200px"
    >
      <div class="drawerCon">
        <div class="editAdsCon">
          <div>
            <div class="campainCon">
              <el-form
                v-model="campainInfo"
                label-width="120px"
              >
                <el-form-item
                  label="广告系列名称："
                  prop="name"
                >
                  <el-input v-model="campainInfo.name"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="adIterm">
              <p class="title">请选择推广业务 :</p>
              <div class="pageList">
                <div
                  :class="['pageItem', index == pageIndex ? 'active' : '']"
                  v-for="(item, index) in pages"
                  :key="index"
                >
                  <div :class="['iconfont', item.icon]"></div>
                  <p>{{ item.name }}</p>
                  <span class="tips">
                    {{ item.tips }}
                  </span>
                  <i class="iconfont icon-duihao"></i>
                </div>
              </div>
            </div>
            <div class="adIterm">
              <p class="title">请选择营销目标 :</p>
              <div class="pageList">
                <div
                  :class="['pageItem', 'targetItem', index == targetIndex ? 'active' : '']"
                  v-for="(item, index) in targets[pageIndex]"
                  :key="index"
                >
                  <div :class="['iconfont', item.icon]"></div>
                  <p>{{ item.name }}</p>
                  <span class="tips">
                    {{ item.tips }}
                  </span>
                  <i class="iconfont icon-duihao"></i>
                </div>
              </div>
            </div>
            <!-- <ad-series :initData="initData" :accountId="accountId" :campainInfo="campainInfo" :edit="true" @adSeriesMsg="adSeriesMsg"></ad-series>
                <div class="cover" v-if="preview"></div> -->
            <el-form label-width="140px">
              <el-form-item
                label="广告系列预算优化："
                class="blod"
              >
                <el-switch
                  v-model="campainInfo.is_cbo"
                  disabled
                ></el-switch>
                <p class="tips">
                  启用广告系列预算优化(CBO)，系统会将广告系列预算根据广告组的实时表现分配给广告组，以获得更多转化。
                </p>
              </el-form-item>
              <el-form-item
                :label="`广告${campainInfo.is_cbo ? '系列' : '组总'}预算：`"
                class="blod"
                v-if="campainInfo.is_cbo"
              >
                <el-input v-model="campainInfo.budget">
                  <el-select
                    v-model="campainInfo.budget_type"
                    slot="prepend"
                    placeholder="请选择"
                    disabled
                  >
                    <el-option
                      label="单日预算"
                      value="daily"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="lifetime"
                    ></el-option>
                  </el-select>
                  <el-button slot="append">{{ currencyName }}</el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            class="cover"
            v-if="preview"
          ></div>
        </div>
      </div>
      <div
        class="demo-drawer__footer"
        v-if="!preview"
      >
        <p></p>
        <div>
          <el-button
            type="primary"
            @click="sure"
            >保存</el-button
          >
        </div>
        <p></p>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import adSeries from './adSeries';
import { adForUpdate, updateDraftCampaign } from '@/api/creatAd.js';
export default {
  props: ['editCampainShow', 'initData', 'id', 'accountId', 'preview'],
  data() {
    return {
      campainInfo: {},
      adSeriesInfo: {},
      pageIndex: 0,
      targetIndex: 1,
      pages: [
        { name: '网站', icon: 'icon-wangzhan', tips: '吸引更多用户访问或在网站上购买、订阅等有价值的操作。' },
        // {name:"移动应用（APP）",icon:"icon-shouji",tips:"推广移动应用，提升应用的下载、激活、付费等。"},
        { name: 'Facebook Page', icon: 'icon-fb', tips: '推广主页和帖子，提升账号的粉丝和互动量。' },
        // {name:"消息互动",icon:"icon-xiaoxi",tips:"通过消息互动，了解用户喜好、提升购买、订阅量等。"},
      ],
      targets: [
        [
          // {name:"吸引更多网站访客",icon:"icon-wangzhan",tips:"让更多目标用户访问您的网站。"},
          { name: '转化量', icon: 'icon-wangzhan', tips: '让更多用户在您的网站或应用执行更多有价值的操作。' },
          // {name:"目录促销",icon:"icon-wangzhan",tips:"根据目标受众动态展示目录中的商品。"},
          // {name:"闪登广告",icon:"icon-wangzhan",tips:"用网站中最受欢迎的商品快速生成广告创意和投放。"}
        ],
        // [{name:"推广应用",icon:"icon-wangzhan",tips:"让更多目标用户逐步到应用商店下载您的应用。"},{name:"转化量",icon:"icon-wangzhan",tips:"让更多用户在您的网站或应用执行更多有价值的操作。"}],
        [
          { name: '主页赞', icon: 'icon-wangzhan', tips: '让更多的用户点赞主页，成为主页粉丝。' },
          { name: '帖文互动', icon: 'icon-wangzhan', tips: '让更多用户浏览你的主页帖子，并与之互动。' },
        ],
        // [{name:"吸引更多用户发消息",icon:"icon-wangzhan",tips:"向用户展示可在Messenger与你互动的广告。"},{name:"转化量",icon:"icon-wangzhan",tips:"让更多用户在您的网站或应用执行更多有价值的操作。"}]
      ],
    };
  },
  components: {
    adSeries,
  },
  computed: {
    currencyName() {
      return this.$store.getters.currentUiUnit.name;
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit('close', 'editCampainShow');
    },
    // 点击保存
    sure() {
      this.$showLoading();
      let params = JSON.parse(JSON.stringify(this.campainInfo));
      params[params['budget_type'] + '_budget'] = this.$store.getters.toBaseMoney(params.budget);
      updateDraftCampaign({ campaign: params, draftCampaignId: this.id }).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('getList');
          this.close();
        }
      });
    },
    // 接收广告系列信息
    adSeriesMsg(v) {
      // console.log(v);
      this.adSeriesInfo = v;
    },
    getInitData() {
      adForUpdate({ draftId: this.id, level: 'campaign', type: 'DRAFT' }).then((res) => {
        // console.log(res);
        this.campainInfo = res.data.campaign;
        if (res.data.campaign.is_cbo) {
          // 日预算
          if (res.data.campaign.daily_budget) {
            this.campainInfo.budget_type = 'daily';
          } else if (res.data.campaign.lifetime_budget) {
            this.campainInfo.budget_type = 'lifetime';
          }
          this.$set(
            this.campainInfo,
            'budget',
            this.$store.getters.toFormatedMoney(res.data.campaign.daily_budget || this.campainInfo.lifetime_budget)
              .value,
          );
        }
      });
    },
  },
  watch: {
    editCampainShow() {
      if (this.editCampainShow) {
        this.getInitData();
      }
    },
  },
};
</script>
<style lang="scss">
.editAdsCon {
  position: relative;
  padding: 0 20px;
  padding-top: 20px;
}
.adIterm {
  margin-bottom: 20px;
  padding: 0 20px;
  .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin: 20px 0;
  }

  .pageList {
    margin: 20px 0;
    display: flex;
    align-items: stretch;
    .pageItem {
      width: 260px;
      margin: 15px;
      padding: 20px;
      border: 1px solid #dddddd;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      &.targetItem {
        width: 240px;
      }
      i.icon-duihao {
        position: absolute;
        top: 10px;
        right: 10px;
        &::before {
          font-size: 20px;
          color: transparent;
        }
      }
      &.active {
        border-color: #247ae6;
        i.icon-duihao {
          &::before {
            color: #247ae6 !important;
          }
        }
      }
      &:hover {
        transition: 0.5s all;
        transform: scale(1.05);
        i.icon-duihao {
          &::before {
            color: #ddd;
          }
        }
      }
      .iconfont {
        margin-bottom: 10px;
      }
      .iconfont::before {
        font-size: 36px;
        color: #247ae6;
      }
      p {
        font-size: 18px;
        color: #555;
        font-weight: bold;
        margin: 15px 0;
      }
      .tips {
        font-size: 14px;
        color: #999;
        line-height: 1.5;
      }
    }
  }
}
</style>
