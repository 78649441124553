<template>
    <div>
        <el-dialog 
            :title="`批量修改${nameLevel=='adset'?'广告组':'广告'}名称`" 
            :visible="editNameShow" 
            width="800px" 
            @close="close"
            :append-to-body="true"
            :modal-append-to-body="false">
            <div>
                <el-form :model="adName" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item :label="`${nameLevel=='adset'?'广告组':'广告'}名称：`" prop="name">
                        <el-input v-model="adName.name"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { mutateName} from "@/api/adManagement";
    export default {
        props:['editNameShow','nameLevel','list'],
        data() {
            return {
                adName:{
                    name:''
                }
            }
        },
        methods: {
            close(){
                this.adName.name = '';
                this.$emit('close','editNameShow');
                this.$emit('update:editNameShow',false)
            },
            sure(){
                if(!this.adName.name){
                    this.$message({
                        type:'warning',
                        message:'名称不能为空'
                    })
                }
                
                if(this.list){
                    let ids = this.list.map(v=>v.id);
                    this.$showLoading();
                    mutateName(JSON.stringify({ids:ids,name:this.adName.name,level:this.nameLevel},this.list[0].rowThirdNum)).then(res=>{
                        this.$hideLoading()
                        if(res.code == 0){
                            this.$message({
                                type:'success',
                                message:'保存成功'
                            })
                            this.$emit('get-list')
                            this.close()
                        }
                    })
                }else{
                    this.$emit('get-name',this.adName.name)
                    this.close();
                }
                
            }
        },
    }
</script>
<style lang="scss">
</style>